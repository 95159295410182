import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import PageLayout, { EmptyLayout } from 'lux/components/PageLayout';
import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';
import { useSelector } from 'lux/modules/useSelector';

export enum TileMode {
    Constructor = 'constructor',
    EditDescription = 'editDescription',
    View = 'view',
}

const TrlKeys = {
    [TileMode.Constructor]: 'employer.constructor.page.title',
    [TileMode.EditDescription]: 'employer.edit.simple.page.title',
    [TileMode.View]: 'employerview.meta.title',

    metaDescription: 'employerview.meta.description',
};

interface EmployerPageTitleProps {
    mode: TileMode;
    employerName?: string | null;
}

const EmployerPageTitle: TranslatedComponent<EmployerPageTitleProps> = ({ trls, mode, employerName, children }) => {
    const topLevelDomain = useSelector((state) => state.topLevelDomain);
    const isEmployerConstructor = useSelector(({ router }) => router.location.pathname === paths.employerConstructor);
    let title = trls[TrlKeys[isEmployerConstructor ? TileMode.Constructor : mode]];
    let description;
    if (mode === TileMode.View && !isEmployerConstructor) {
        title = format(title, { '{0}': employerName || '' });
        description = format(trls[TrlKeys.metaDescription], {
            '{0}': employerName || '',
            '{1}': topLevelDomain || '',
        });
    }
    return (
        <PageLayout title={title} description={description} layout={EmptyLayout}>
            {children}
        </PageLayout>
    );
};

export default translation(EmployerPageTitle);
